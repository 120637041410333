// import '../styles/globals.css';

import type { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';

import { Amplify } from 'aws-amplify';

import awsConfigStaging from 'src/aws-exports-dev';
import awsConfigProd from 'src/aws-exports-main';
import 'focus-visible/dist/focus-visible';
import theme from '../styles/theme';

import '../styles/focus-visible.css';
import { NextPage } from 'next';
import React, { ReactElement, ReactNode } from 'react';
import { ParameterStoreProvider } from 'utils/context';
import { QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// AWS export options
Amplify.configure(
  process.env.NODE_ENV === 'production'
    ? process.env.NEXT_PUBLIC_AWS_EXPORT_TYPE == 'STAGING'
      ? { ...awsConfigStaging, ssr: true }
      : { ...awsConfigProd, ssr: true }
    : { ...awsConfigStaging, ssr: true }
);

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60 * 1000,
          },
        },
      })
  );

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <QueryClientProvider client={queryClient}>
      <ParameterStoreProvider>
        <ChakraProvider theme={theme}>
          {getLayout(<Component {...pageProps} />)}
          <ReactQueryDevtools initialIsOpen={false} />
        </ChakraProvider>
      </ParameterStoreProvider>
    </QueryClientProvider>
  );
}

export default MyApp;
